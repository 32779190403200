import './HomeHeader.styles.css';

import HeaderImage from '../../../assets/image00028.jpeg';

function HomeHeader() {

    /**
     * This function scrolls into the specified element.
     * @param {stirng} elementId the id of the element to scroll to
     */
    function scrollTo(elementId) {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error('Element not found!');
        }
    }

    return (
        <>
            <div id='navigation-menu'>
                <button onClick={() => scrollTo('rooms')} className='navigation-menu-button'>Szobáink</button>
                <button onClick={() => scrollTo('prices')} className='navigation-menu-button'>Árlista</button>
                <button onClick={() => scrollTo('contact')} className='navigation-menu-button'>Kapcsolat</button>
            </div>
            <div id='home-header-container' style={{ backgroundImage: `url(${HeaderImage})` }}>
                <div id='home-header-overlay'>
                    <div>
                        <h1 id='home-header-title'>Hatod Vendégház</h1>
                        <p id='home-header-subtitle'>- Balatongyörök -</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomeHeader;