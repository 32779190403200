import Nr3Room1 from '../../assets/rooms/nr3/1.jpg';
import Nr3Room2 from '../../assets/rooms/nr3/2.jpg';
import Nr3Room3 from '../../assets/rooms/nr3/3.jpg';

import Nr4Room1 from '../../assets/rooms/nr4/1.jpg';
import Nr4Room2 from '../../assets/rooms/nr4/2.jpg';
import Nr4Room3 from '../../assets/rooms/nr4/3.jpg';

import Nr5Room1 from '../../assets/rooms/nr5/1.jpg';
import Nr5Room2 from '../../assets/rooms/nr5/2.jpg';
import Nr5Room3 from '../../assets/rooms/nr5/3.jpg';

import Nr6Room1 from '../../assets/rooms/nr6/1.jpg';
import Nr6Room2 from '../../assets/rooms/nr6/2.jpg';
import Nr6Room3 from '../../assets/rooms/nr6/3.jpg';

const ROOMS = [
    {
        id: 'Nr3',
        name: 'Standard erkélyes',
        location: 'Emeleti, erkélyes',
        size: '15 m²',
        capacity: '2 fő',
        bed: '2 külön ágy',
        otherRooms: 'Fürdőszoba, zuhanyzóval',
        images: [
            Nr3Room1,
            Nr3Room2,
            Nr3Room3
        ],
        startPrice: '18.000 Ft-tól',
        services: [
            'Klíma',
            'WiFi',
            'Síkképernyős TV',
            'Erkély',
            'Zárt parkoló',
            'Reggelizési lehetőség (igény esetén)'
        ]
    },
    {
        id: 'Nr4',
        name: 'Standard',
        location: 'Emeleti',
        size: '15 m²',
        capacity: '2 fő + pótágy',
        bed: '2 külön ágy',
        otherRooms: 'Fürdőszoba, zuhanyzóval',
        images: [
            Nr4Room1,
            Nr4Room2,
            Nr4Room3
        ],
        startPrice: '18.000 Ft-tól',
        services: [
            'Klíma',
            'WiFi',
            'Síkképernyős TV',
            'Terasz',
            'Zárt parkoló',
            'Reggelizési lehetőség (igény esetén)'
        ]
    },
    {
        id: 'Nr5',
        name: 'Standard',
        location: 'Emeleti',
        size: '20 m²',
        capacity: '2 fő + pótágy',
        bed: '2 külön ágy',
        otherRooms: 'Fürdőszoba, zuhanyzóval',
        images: [
            Nr5Room1,
            Nr5Room2,
            Nr5Room3
        ],
        startPrice: '18.000 Ft-tól',
        services: [
            'Klíma',
            'WiFi',
            'Síkképernyős TV',
            'Terasz',
            'Zárt parkoló',
            'Reggelizési lehetőség (igény esetén)'
        ]
    },
    {
        id: 'Nr6',
        name: 'Standard erkélyes családi',
        location: 'Emeleti, erkélyes',
        size: '15 m²',
        capacity: '4 fő',
        bed: '2 külön ágy + 1 franciaágy',
        otherRooms: 'Fürdőszoba, fürdőkáddal',
        images: [
            Nr6Room1,
            Nr6Room2,
            Nr6Room3
        ],
        startPrice: '32.000 Ft-tól',
        services: [
            'WiFi',
            'Síkképernyős TV',
            'Erkély',
            'Zárt parkoló',
            'Reggelizési lehetőség (igény esetén)'
        ]
    }
];

export default ROOMS;