import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/home/Home.page';

function App() {
  return (
    <Home />
  );
}

export default App;
