import { useEffect, useState } from 'react';
import './RoomDetails.styles.css';
import RoomImageViewer from './RoomImageViewer.component';

function RoomDetails(props) {

    const [viewerOpened, setViewerOpened] = useState(false);

    const openImageViewer = () => {
        setViewerOpened(true)
        document.body.classList.add('no-scroll');
    };

    const closeImageViewer = () => {
        document.body.classList.remove('no-scroll');
        setViewerOpened(false);
    };
    return (
        <>
            {viewerOpened && <RoomImageViewer onClose={closeImageViewer} room={props.room} />}

            <div className='room-details-container'>
                <div className="room-details-avatar-container">
                    <img onClick={openImageViewer} className='room-avatar-image' src={props.room.images[0]} />
                </div>
                
                <div className='room-details-content'>
                    <h2 className="room-name">{props.room.name}</h2>

                    <p className="room-detail" style={{ color: 'gray', marginBottom: 16 }}>{props.room.location}</p>

                    <p className="room-detail">Méret: {props.room.size}</p>
                    <p className="room-detail">Kapacitás: {props.room.capacity}</p>
                    <p className="room-detail">Ágytípus: {props.room.bed}</p>
                    <p className="room-detail">Egyéb helységek: {props.room.otherRooms}</p>

                    <div>
                        <p style={{ fontWeight: 'bold' }}>Kényelmi szolgáltatások</p>
                        <ul>
                            {props.room.services.map(service => (
                                <li key={service} className="service-list-item">{service}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoomDetails;