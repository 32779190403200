import './RoomImageViewer.styles.css';
import { useState } from 'react';

import ArrowRight from '../../../assets/icons/arrow-right.svg';
import ArrowLeft from '../../../assets/icons/arrow-left.svg';
import XMark from '../../../assets/icons/xmark.svg';

type Props = {
    room: any,
    onClose: any
};

function RoomImageViewer(props) {

    const [imageIndex, setImageIndex] = useState(0);

    /**
     * Modifies the image index by the given ammount of step.
     * @param {number} increment -1 or 1. the ammount of the step 
     */
    const modifyImageIndex = (increment) => {
        const newIndex = imageIndex + increment;
        if (newIndex < 0) {
            setImageIndex(0);
        } else if (newIndex > (props.room.images.length - 1)) {
            setImageIndex(props.room.images.length - 1);
        } else {
            setImageIndex(newIndex);
        }
    };

    /**
     * Closes the image viewer component.
     */
    const close = () => {
        props.onClose();
    };

    return (
        <div id="room-image-viewer-window">
            <img onClick={close} id="room-image-viewer-close" src={XMark} />
            <h1>{ props.room.name }</h1>
            <p>{ props.room.location }</p>
            <div id='room-image-viewer-image-container'>
                <img onClick={() => modifyImageIndex(-1)} className="room-image-viewer-navigation" src={ArrowLeft} />
                <img id="room-image-viewer-image" src={ props.room.images[imageIndex] } />
                <img onClick={() => modifyImageIndex(1)} className="room-image-viewer-navigation" src={ArrowRight} />
            </div>
            <p id="room-image-viewer-index">{imageIndex + 1} / {props.room.images.length}</p>
        </div>
    );
}

export default RoomImageViewer;